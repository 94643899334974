$(function () {

    if (!localStorage.getItem('changePricing')) {
        $.ajax({
            url: path_relative_root + 'ajax_tarification_list.php',
            type: 'post',
            dataType: 'json',
            data: {countryByIp: true},
            success: function (res) {
                if (res !== []) {

                    var country_iso = '';

                    $.each(res, function (key, val) {
                        //Récupération de l'iso pays selectionné, ici par rapport a l'ip
                        if (val.selected) {
                            country_iso = val.iso3;
                        }
                    });

                    // On va envoyer au serveur que l'on change de pays
                    $.ajax({
                        url: path_relative_root + "ajax_tarification_change_country.php",
                        type: 'post',
                        data: 'iso=' + country_iso,
                        success: function () {
                            localStorage.setItem('changePricing', 'true');
                            location.reload(); // Refresh the page
                        }
                    });
                }
            }
        });
    }

    // INIT SWIPERS
    if ($('.img_store .swiper-slide').length > 1) {
        setTimeout(function () {
            new Swiper('.img_store.swiper-container', {
                slidesPerView: 1,
                loop: true,
                spaceBetween: 21,
                slidesOffsetBefore: 107,
                slidesOffsetAfter: 107,
            });
        }, 600);
    }
    // Init Swiper bandeau
    if ($("#sliderBando .swiper-slide").length > 1) {
        setTimeout(function () {
            var preheaderMobile = new Swiper("#sliderBando", {
                slidesPerView: 1,
                speed: 2000,
                effect: "slide",
                direction: "horizontal",
                loop: true,
                autoplay: {
                    delay: 5000,
                },
                roundLengths: true,
                simulateTouch: true,

            });
        }, 800);
    }

    // Ajout Information CLient page Connexion
    // Seulement afficher le message si la clé de traduction n'est pas vide
    if (Translator.translate('message_information') != 'message_information') {
        var createElementsHTML = '<p class="contentAlert"></p>';
        $('.body_login .w-newlogin-form').append(createElementsHTML);
        $('.contentAlert').html(Translator.translate('message_information'));
    }


    //MOVE ARIANE TEXTE PAGES FUNCTION
    if (($('.wrapper_content_sat.page_text').length)) {
        $('.main_ariane').insertAfter($('.wrapper_content_sat.page_text .title'));
        $('.wrapper_content_sat.page_text .title').css('opacity', '1');

    }

    //MOVE ARIANE RAYON FUNCTION
    if (($('body.category').length)) {
        $('.main_ariane').insertAfter($('.site_contents_wrapper > .title'));
        $('.site_contents_wrapper > .title').css('opacity', '1');
        $('.site_contents_wrapper .main_ariane').css('opacity', '1');

    }

    // Move tunnel ariane below title for basket page, without override view or js function
    moveTunnelElements();

    /* STORE TITLE */
    setTimeout(function () {
        if (($('.store_locator .tunnel_step_title').length) || ($('.satellite_store_details .tunnel_step_title').length)) {
            $('.main_ariane').insertAfter($('.tunnel_step_title'));
        }
        $('.tunnel_step_title').css('opacity', '1');
        $('.main_ariane').css('opacity', '1');
    }, 500);

    /* CONTACT FAQ */
    setTimeout(function () {
        if (($('.contact_faq.mobile').length)) {
            $('.main_ariane').insertAfter($('.titre_contact'));
            $('.main_ariane').css('margin', '0');
            $('.main_ariane').css('opacity', '1');
            $('.title_container_contact').css('opacity', '1');
            $('.required-fields').css('opacity', '1');
        }
    }, 500);

    /* SITEMAP */
    setTimeout(function () {
        if (($('.footer_sitemap.mobile').length)) {
            $('.main_ariane').insertAfter($('#wrapper_sitemap h1'));
            $('.main_ariane').css('margin', '0');
            $('.main_ariane').css('opacity', '1');
            $('#wrapper_sitemap h1').css('opacity', '1');
        }
    }, 500);

    /**
     * Account dropdown from menu
     */
    if ($('#left_menu .bloc_lnk.user.connected.dropdown').length > 0) {

        $(document).on('click tap touch', '.bloc_lnk.user.connected.dropdown', function () {

            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                $(this).parent().find('.dropdown.mon-compte').slideUp(300);
                $("#m_animator .list_menu.top").first().css("margin-top", "24px");
            } else {
                $(this).addClass('active');
                $(this).parent().find('.dropdown.mon-compte').slideDown(300);
                $("#m_animator .list_menu.top").first().css("margin-top", "0px");
            }
        });
    }

    /* Footer accordeon */
    $('.wrap_links .col_title').each(function () {
        $(this).click(function () {
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                $(this).parent().find('.col_content.active').removeClass('active').slideUp(300);
            } else {
                $('.wrap_links .col_title.active').removeClass('active');
                $('.wrap_links .col_content.active').removeClass('active').slideUp(300);
                $(this).addClass('active');
                $(this).parent().find('.col_content').addClass('active').slideDown(300);
            }
        });
    });

    /**
     * Swiper mobile "Mon compte" menu
     */

    if (($('body').hasClass('customer') || $('body').hasClass('newsletter') || $('body').hasClass('order') || $('body').hasClass('wishlist') || $('body').hasClass('faq')) && $('#menu_account_slider_mob').length > 0) {

        var menuInitialSlide = 0;

        $('.menu_account_slider_mob').show();

        $('.menu_account_slider_mob .swiper-slide').each(function (i, obj) {

            if ($(obj).hasClass('activeMenu')) {

                menuInitialSlide = i;
            }
        });

        var menuAccSwiperMob = new Swiper('#menu_account_slider_mob', {
            slidesPerView: 2,
            centeredSlides: true,
            initialSlide: menuInitialSlide,
            onInit: function () {

                $('.menu_account_slider_mob').fadeTo("slow", 1);
            },
        });
    }
    setTimeout(function () {
        // Switch langues menu
        if ($('#left_menu .switchLangWrapper').length) {
            $('#left_menu .current').on('click touch', function () {
                $(this).toggleClass('open');
                $('.switchLangWrapper .langues').slideToggle();
            });
        }
    }, 600);

    /* WISHLIST PAGE SELECT ALL */
    if ($(".wishlist .wrapper_btn_top")) {
        $(".wrapper_btn_top").appendTo(".wish_liste_product");
    }

    // fix to show date of birth place holder, needs to be optimized
    if ($('#wrapper_moncompte .date_placeholder').length > 0) {
        $('#wrapper_moncompte .date_placeholder').click(function () {
            $("#clientDatenais").triggerHandler("focus");
        });
        $("#clientDatenais").focusin(function () {
            $("#wrapper_moncompte .date_placeholder").css("display", "none");
        });
    }

    // SWIPER => cover-module

    if ($('#coverSwiper .swiper-slide').length > 1) {
        var coverModuleHomeSwiper = new Swiper("#coverSwiper", {
            loop: true,
            autoplay: {
                delay: 5000,
            },
            speed: 2000,
            slidesPerView: 1,
            spaceBetween: 40,
            navigation: {
                nextEl: ".cover-module .cover-next",
                prevEl: ".cover-module .cover-prev",
            },
            roundLengths: true,
        });
    }

    // SWIPER => multiple-bloc

    var homeProductListWrapper = new Swiper(".home-product-list-wrapper", {
        slidesPerView: 1.7,
        spaceBetween: 9,
        lazy: true,
        pagination: {
            el: ".swiper-pagination",
            type: "progressbar",
        },
        roundLengths: true,
    });

    if ($(".home-product-list-wrapper").length) {
        var cnt = 0;
        $(".home-product-list-wrapper").each(function (e) {
            cnt++;
            $(this).addClass('product-list-' + cnt);
            var pagination = $(this).next('.swiper-pagination').addClass('swiper-pagination-' + cnt);
            var articlesCms = new Swiper('.product-list-' + cnt, {
                spaceBetween: 9,
                slidesPerView: 1.7,
                pagination: {
                    el: "swiper-pagination",
                    type: "progressbar",
                },
                roundLengths: true,
            });
        })
    }

    // SWIPER => Assos_rayon => fiche produit

    if ($('.product_page .assos_rayon').length) {
        new Swiper('.product_page .assos_rayon', {
            slidesPerView: 1.6,
            spaceBetween: 8,
            pagination: {
                el: ".assos_rayon .swiper-pagination",
                type: "progressbar",
            },
        });
    }

    // SWIPER => Assos_produit => fiche produit

    if ($('.product_page .assos_product').length) {
        $("<div class='swiper-pagination'></div>").appendTo(".product_page .assos_product");
        new Swiper('.product_page .assos_product', {
            slidesPerView: 1.6,
            spaceBetween: 8,
            pagination: {
                el: ".swiper-pagination",
                type: "progressbar",
            },
            roundLengths: true,
        });
    }

    if ($('body.step_1 .pushBasketWrapperText').length) {
        $('.pushBasketWrapperText').prependTo($('body.step_1 .sub_tunnel.basket'));
    }

    // lightbox dispo magasin
    if ($("#availabilityInStore").length) {
        $("#ctaAvailabilityInStore").on("click", function () {
            if ($("#prod_taille").val() != '') {
                $("body.product_page").addClass("noscroll");
            }
        });

        $("#availabilityInStore input.close_pop").on("click", function () {
            $("body.product_page").removeClass("noscroll");
        });

        $("#shade").on("click, touch, tap", function () {
            setTimeout(function () {
                if ($('#shade').is(":visible") == false) {
                    $("body.product_page").removeClass("noscroll");
                }
            }, 1400);
        });
    }

    // SWIPER Produit => Fiche Produit
    if ($('#zoom_container .swiper-slide').length > 1) {
        new Swiper('#zoom_container .swiper-container', {
            slidesPerView: 1,
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true,
            },
            preloadImages: true,
            lazyLoading: true,
            loop: true,
        });
    }

    $(document).scroll(function () {

        if ($(this).scrollTop() > 250) {
            $('body.product_page').addClass('scrolled');
        } else {
            $('body.product_page').removeClass('scrolled');
        }
        if ($(this).scrollTop() > 900) {
            $('#floating_add_basket').removeClass('scrollUp');
            $('#floating_add_basket').addClass('scrollDown');
        } else {
            $('#floating_add_basket').removeClass('scrollDown');
            $('#floating_add_basket').addClass('scrollUp');
        }
        if ($(this).scrollTop() == 0 && $('body.product_page #left_menu').hasClass('open')) {
            $('body.product_page .bandeauMenuAffix, body.product_page #site_head').css({
                'opacity': '1',
                'z-index': '30'
            });
            $('body.product_page').css('padding-top', '17.6vmin');
        }
    });

    $('#recherche_title').on('click', function () {
        searchToggle();
    });

    // positionnement éclats fiche produits
    if ($('.product_page .eclat_product.rect').length) {
        $('.eclat_product.round#eclat_product').css('right', '132px');
        $('#eclat_product_reduc.eclat_product.round').css('right', '132px');
    }

    // Wishlist page wording for size element --> Match desktop display
    if ($('body.wishlist').length) {
        $('.wishlistProdSize>label').each(function () {
            $(this).html('');
            $(this).html(Translator.translate("wishlist_product_size") + " :");
        })
    }

    // Tunnel eclats on products
    if ($('body.cart .cart_item_container').length) {
        $('body.cart .cart_item_container').each(function () {
            if ($(this).find('>.eclat_privilege').length) {
                $(this).find('>.eclat_privilege').addClass('eclat_first');
            }
        });
    }

    // Rayon auto scroll
    if ($('body.category').length > 0) {
        // Set a timer to prevent too much triggering
        var timer;
        $(document).on('scroll', function () {
            // Prevent script from triggering too much and causing blank search results
            if (timer) {
                window.clearTimeout(timer);
            }

            timer = window.setTimeout(function () {
                // If scroll (including page height) is above loader_scroll position, trigger btn to load next page
                if (($(window).scrollTop() + $(window).height()) > ($('.wrap_flitre_pager .loader_scroll').offset().top) + $('header.banner_wrapper').outerHeight()) {
                    $('.wrap_flitre_pager .loader_scroll').trigger('click');
                    $('.wrap_flitre_pager .loader_scroll .loader').addClass('loading');
                }
            }, 300);
        });
    }

    // Texte placeholder ajout adresse "mon compte"
    $('.customer #clientForm .intl-tel-input #clientTel').attr('placeholder', Translator.translate('texte_numero_telephone'));

    // JS pour les FP
    if ($('body').hasClass('product_page')) {
        //Pour l'affichage du cta dispo mag si pas de stock
        $(document).on('change', '#prod_taille', function () {
            if ($(".cta_availability_wrapper").length) {
                if ($("#btn_add_alert").is(":visible")) {
                    $(".cta_availability_wrapper").show();
                } else {
                    $(".cta_availability_wrapper").hide();
                }
            }
        });
    }

    /* Tunnel FAQ dynamic - Keep last section (contact info) open on page load and hide the question */
    if (($('body.cart').length) && $('.bloc_question').length) {
        $('.faq_theme_wrapper').last().find('.intitule_question').hide();
        $('.faq_theme_wrapper').last().find('.accordion_contents.texte_question').show();
    }

    $('.misc_cookiesinfo .content_sat .cookieShowLightbox').click(function () {
        $('.misc_cookiesinfo #cookiesLightbox').addClass('displayCookies');
        if ($('.misc_cookiesinfo #cookiesLightbox').has('.hideCookies')) {
            $('.misc_cookiesinfo #cookiesLightbox').removeClass('hideCookies');
        }
        $('.misc_cookiesinfo #cookiesLightbox .w-form-line.w-submit button.w-loader').addClass('hideLoader');
    });

    $('.misc_cookiesinfo #js_cookie_refuse, .misc_cookiesinfo #js_cookie_accept').click(function () {
        if ($('.misc_cookiesinfo #cookiesLightbox').has('.displayCookies')) {
            setTimeout(() => {
                $('.misc_cookiesinfo #cookiesLightbox').removeClass('displayCookies');
                $('.misc_cookiesinfo #cookiesLightbox').addClass('hideCookies');
                $('.misc_cookiesinfo #shadCookie').removeClass('actif');
            }, 500);
        }
    });

    //By default show number of all products
    if (document.getElementById('nbr_items').length) {
        var initNoItems = document.getElementById('nbr_items').innerHTML;
        $("#filters_form_submit").html(Translator.translate("apply_item_filters") + " (" + parseFloat(initNoItems) + ")");
    }
});

function createEtiquetteRetour(idOrder, parentPath, multiTransp, idOrderTransp) {

    var formId = '#return_products_form';
    formId += '_' + (multiTransp ? idOrderTransp : idOrder);

    var has_errors = false;

    $(formId + " .return_quantity").each(function () {
        var return_choice = $(this).closest('.js-select-motif').find('.return_choice');
        return_choice.removeClass('error');

        if ($(this).val() > 0 && return_choice.val() == '0') {
            has_errors = true;
            return_choice.addClass('error');
        }
    });

    if (!has_errors) {

        let data = $("#return_products_form_" + idOrder).serialize();
        data += '&idOrder=' + (multiTransp ? idOrderTransp : idOrder) + '&multiTransp=' + multiTransp;

        $.ajax({
            url: path_relative_root + 'ajax_create_etiquette.php',
            type: 'post',
            data: data,
            success: function (res) {
                location.reload();
            }
        });
    }
}

// Commande mobile end


function showNumRetourEtiquette(
    idOrder,
    multiTransp,
    parentPath,
    idOrderTransp,
    idTransporteur
) {

    var divId = 'popup_numero_retour_' + (multiTransp ? idOrderTransp : idOrder);

    var numDIVObjet = document.getElementById(divId);

    numDIVObjet.style.display = "block";
    numDIVObjet.style.opacity = "1";
    numDIVObjet.style.zIndex = "20";
    document.getElementById("general_shade").style.display = "block";
    document.getElementById("general_shade").style.opacity = ".4";
    document.getElementById("general_shade").style.position = "fixed";
    document.getElementById("general_shade").style.zIndex = "15";
    document.getElementById("general_shade").style.left = "0";
    document.getElementById("general_shade").style.top = "0";
    document.getElementById("general_shade").style.width = "100vw";
    document.getElementById("general_shade").style.height = "100vh";
    document.getElementById("general_shade").style.backgroundColor = "black";

    var scrollTop = $(window).scrollTop(),
        speed = Math.min(scrollTop * 0.8, window.innerHeight);


    $("html, body").animate({scrollTop: 0}, (speed * 2), function () {
    });
}

function showPdfEtiquetteRetour(idOrder, multiTransp, numRetour, show_pdf, idOrderTransp) {
    var idCmdOrder = multiTransp ? idOrderTransp : idOrder;

    if (!show_pdf) {
        data = $('#return_products_form_' + idCmdOrder).serialize();
        data += '&idOrder=' + idCmdOrder + '&multiTransp=' + multiTransp + '&return_number=' + numRetour;
    } else {
        data = 'idOrder=' + idCmdOrder + '&multiTransp=' + multiTransp + '&return_number=' + numRetour;
    }

    $.ajax({
        url: path_relative_root + create_link('ajax_create_etiquette'),
        type: 'post',
        data: data,
        success: function (res) {
            if (res.substr(0, 5) == 'false') {
                alert(Translator.translate('js_error_return_label'));
            } else {
                closeMultiShad('popup_numero_retour');
                if (show_pdf) {
                    window.location.href = res;
                } else {
                    location.reload();
                }
            }
        }
    });
}

function closeMultiShad(id) {
    modBoxClose();
    $("#" + id).removeClass('open');
    $("#availabilityInStore").slideUp(300);
    $("#" + id).fadeOut();
    $('#general_shade').css('display', 'none');
    $('#shade').fadeOut();
    $("#shade").removeClass("forMenu for_store");
    $("body").css("overflow", "auto");
}

function paginationGoToPage(page, fromScrollBottom, fromScrollTop) {

    page = page || 1;

    var type = document.getElementById('type_tri').value;
    var idObj = getCurrentIdRayon();
    var bloc_page = page - 1;
    var content_page = '';

    /**
     * Mise à jour des valeurs de pages d'origine pour le scroll infini
     */
    if ($('#scroll_origin').length) {
        if (!fromScrollBottom && fromScrollTop) {
            $('#scroll_origin').val(page);
        } else if (fromScrollBottom && !fromScrollTop) {
            $('#initial_page').val(page);
        } else {
            $('#scroll_origin, #initial_page').val(page);
        }
    }

    if (fromScrollBottom || fromScrollTop) {
        $(".autoLoad").fadeTo("fast", 1);
    }

    if (from_type == 'product') {
        generateNewBlocProd(type, idObj, page, null, null, fromScrollBottom, fromScrollTop);
    } else {

        generateNewBlocSearch(document.getElementById('nb_prod').value, bloc_page, null, fromScrollBottom, fromScrollTop);
    }
}

function paginate(page) {

    var form = document.getElementById('filters_form'),
        total_element = parseFloat($('#totalElems').val()),
        nb_prod = parseFloat($('#nb_prod').val()),
        nb_total_page = Math.ceil(total_element / nb_prod),
        nb_prod_default = parseFloat($('#nb_prod_default').val()),
        pagination = $('.pagination'),
        see_all = parseFloat($('#see_all').val());

    page = page || 1;

    $.ajax({
        url: path_relative_root + 'ajax_reload_pagination.php',
        type: 'get',
        data: {
            page: page,
            link: 'paginationGoToPage()',
            nb_total_page: nb_total_page
        },
        success: function (res) {

            var productLoaded = $('.item_container').length;
            var percentLoaded = (productLoaded * 100 / total_element);

            pagination.each(function () {

                if (nb_prod < total_element) {
                    this.innerHTML = res + '<div class="nbProdDisplayed">' + Translator.translate('pagerTxt1') + ' ' + productLoaded + ' ' + Translator.translate('pagerTxt2') + ' ' + total_element + '</div><div class="loadedGauge"><span style="width: ' + percentLoaded + '%;"></span></div><div class="see_all_product" onclick="seeXProduct(1000)">' + Translator.translate('see_more_products') + '</div>';
                } else if (total_element >= nb_prod_default) {
                    this.innerHTML = '<div class="nbProdDisplayed">' + Translator.translate('pagerTxt1') + ' ' + productLoaded + ' ' + Translator.translate('pagerTxt2') + ' ' + total_element + '</div><div class="loadedGauge"><span style="width: ' + percentLoaded + '%;"></span></div><div class="see_all_product" onclick="seeXProduct(' + nb_prod_default + ')">' + Translator.translate('see_x_product_by_page', nb_prod_default) + '</div>';
                } else {
                    this.innerHTML = '';
                }
            });
        }
    });
}

function seeXProduct(nb_product, page) {

    var nb_prod_default = parseFloat($('#nb_prod_default').val());

    //Pour afficher tout, on repart de la page courante
    if (nb_product === (nb_prod_default + 1)) {

        $('#scroll_origin').val($("#initial_page").val());
        $('#page').val(page);

        nb_product = nb_prod_default;
        $("#see_all").val("1");
        generateNewBlocProd(null, null, (page - 1));
        $("#initial_page").val(page);

    } else {
        var type = $('#type_tri').val();
        $("#see_all").val("0");
        $("#nb_prod").val(nb_product);
        $("#initial_page").val(1);

        // Si on est sur la page de recherche
        if ($("#search_page").length > 0) {
            generateNewBlocSearch(0);
        } else {
            generateNewBlocProd(type, null, 0);
        }
    }
}

function generateNewBlocProd(type, idObj, page, nb_product, btn, fromScrollBottom, fromScrollTop) {

    if ($('.see_all')) {
        $('.see_all').addClass('loading');
    }

    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filters_form'),
        value = getInputVal.bind(this),
        output = {},
        is_from_page = false,
        nb_product = nb_product || parseFloat($('#nb_prod').val()),
        type = type || $('#type').val(),
        idObj = idObj || parseFloat($('#idObj').val()),
        startPage = parseInt($('#page_start').val()),
        key;

    output = triggerFilter(page, type, idObj, nb_product, 'filters_form');

    // Get all filters
    form = Array.prototype.slice.call(form.elements, 0);

    if (fromScrollTop || fromScrollBottom) {
        $('#is_loading').val('1');
    }


    // Convert filters arrays to strings
    for (key in output) {

        if (output.hasOwnProperty(key)) {
            if (output[key].constructor === Array) {
                output[key] = output[key].join('~');
            }
        }
    }

    // If price range slider available
    if ($("input[name=price_min]").length > 0) {
        // Retrieving the price
        output.prix =
            $("#amount1").val() +
            "~" +
            $("#amount2").val();
    }

    if ($(".filter_date").length > 0) {
        output.multi_object = 'true';
        output.id_category_super = id_category_super;

        if (typeof (output['date']) == 'undefined') {
            output.date = 4;
        } else {
            if (output['date'] == 4)
                output['date'] = tmp_date;
        }
    } else {
        if (output['date'] == 4) {
            output['date'] = tmp_date;
        }
    }

    if ($('#oneProduct').hasClass('actif')) {
        var one_product = true;
    } else {
        var one_product = false;
    }

    if (page === 'all') {

        /** LEGACY **/
        hash = location.hash.substr(1);
        if ((/i=[0-9]+/).test(hash)) {
            // Vu que le header est en absolute on le sort du calcul
            var marginTop = $('.banner_wrapper').height() + 10,
                $elem = $('#' + hash.replace('=', '')),
                offset = $elem.offset(),
                top = offset && offset.top - marginTop;

            window.setTimeout(function () {
                $(window).scrollTop(top);
            }, 1000);
        }

        /** END LEGACY **/
    } else {
        if (bloc_prod_xhr) {

            bloc_prod_xhr.abort();
        }

        bloc_prod_xhr = $.ajax({
            url: path_relative_root + create_link('ajax_reload_products'),
            type: 'get',
            data: output,
            success: function (res) {

                //var new_elm = document.createElement('div');
                var old_elm = document.querySelector('.list_item');
                var content, itm_length;

                if (btn && btn.nextElementSibling && (btn.nextElementSibling.classList.contains('btn_tunnel') || btn.nextElementSibling.classList.contains('loader'))) {

                    btn.nextElementSibling.style.display = 'none';
                }

                res = res.split('><><');

                content = res[0].trim();

                itm_length = parseFloat(res[1]);

                // This block of code updates the SEO-related link elements in the HTML document.
                let canonical = res[5];
                let prev = res[6];
                let next = res[7];

                $("link[rel^='canonical']").attr("href", path_root + canonical);
                $("link[rel^='alternate'][hreflang^='fr']").attr("href", path_root + canonical);

                $("link[rel^='alternate'][hreflang^='en']").attr("href", JSON.parse(res[8]).en);

                let hrefPrev = $("link[rel^='prev']");
                let hrefNext = $("link[rel^='next']");
                let commentPrev = $('#href_prev');
                let commentNext = $('#href_next');

                changeSeoElement(prev, hrefPrev, commentPrev, 'prev');
                changeSeoElement(next, hrefNext, commentNext, 'next');

                if ($('.rayonCategSlider').length && output.object_id) {

                    $('.rayonCategSlider .menuitem').removeClass('actif');
                    $(".menuitem[data-idobj='" + idObj + "']").addClass("actif");

                    var rayonInitialSlide;

                    $('.rayonCategSlider .swiper-slide').each(function (i, obj) {
                        if ($(obj).hasClass('actif')) {
                            rayonInitialSlide = i;
                        }
                    });

                    rayonCategSlider.slideTo(rayonInitialSlide);
                } else {
                    $('.rayonCategSlider .menuitem').removeClass('actif');
                }

                if (!isNaN(page)) {
                    old_elm.setAttribute('data-p', (page * nb_product));
                }

                if (fromScrollBottom || fromScrollTop) {
                    $(".autoLoad").fadeTo("fast", 0);
                } else {
                    old_elm.innerHTML = "";
                }

                document.getElementById('nbr_items').innerHTML = itm_length;
                document.getElementById('totalElems').value = itm_length;

                paginate(page + 1);

                if (content !== '') {
                    if (fromScrollTop) {
                        var firstMsg = $('#scroll_items .item_container:first');
                        var curOffset = firstMsg.offset().top - $(document).scrollTop();
                        old_elm.insertAdjacentHTML('afterbegin', content);
                        $(document).scrollTop(firstMsg.offset().top - curOffset);
                        //L'inverse pour le bas
                    } else {
                        old_elm.insertAdjacentHTML('beforeend', content);
                    }

                    if (one_product) {
                        $('div.item_container').addClass('full');
                    }

                    /** LEGACY **/

                    var js_compteur = $('.vf_countdown').length;
                    var js_encart = content.match(/\/\/vf_begin([\s]+[\w$(){}\[\]=;\0,\'\".:\/<&!+% ]*)+\/\/vf_end/g);

                    if (js_compteur != null) {
                        try {
                            var cnt_compteur = js_compteur.length;

                            for (var i = 0; i < cnt_compteur; i++) {
                                eval(js_compteur[i].replace(/(vf_compteur_begin|vf_compteur_end)/g, ""));
                            }
                        } catch (e) {
                        }
                    }
                    if (js_encart != null) {
                        try {
                            js_encart = js_encart[0].replace(/(\/\/vf_begin|\/\/vf_end)/g, "");
                            eval(js_encart);
                        } catch (e) {
                        }
                    }

                    /** END LEGACY **/

                    if (window.lazyload) {

                        lazyload.init();
                    }

                    if (typeof lazyloadImage === 'function') {
                        lazyloadImage();
                    }

                } else {
                    // No products found
                    old_elm.insertAdjacentHTML('beforeend', '<p class="no-result">' + Translator.translate('title_no_result') + "</p>");
                }

                if (parseFloat($('#filters_menu').css('right')) === 0) {
                    toggleMenu('filters', 'right');
                }

                /* Add total product number of filtered items */
                $("#filters_form_submit").html(Translator.translate("apply_item_filters") + " (" + parseFloat(itm_length) + ")");
            },
            complete: function () {
                $('#totalElems').trigger('change');
                $('#bottom_reached').val("0").trigger('change');
                $('#page').trigger('change');
                $(".autoLoad").fadeTo("fast", 0);

                /**
                 * Retour vers le haut du rayon :
                 * uniquement dans le cas d'une pagination classique (see_all.val() == 0).
                 * Si initialisation du scroll infini ((see_all.val() == 1)), on ne remonte que de quelques
                 * ps pour déclencher le chargement de la page suivante.
                 */
                if (!fromScrollBottom && !fromScrollTop) {
                    if ($('.item_container').length <= nb_product + 1) {
                        if ($('#see_all').val() == 1) {
                            window.scrollTo(0, ($('#scroll_items').offset().top + ($('#scroll_items').height() - $('.item_container').height() * 2)));
                        } else {
                            backToTop();
                        }
                    } else {
                        setTimeout(function () {
                            if ($('#see_all').length > 0 && $('#see_all').val() == 0) {

                                $("html, body").animate({
                                    scrollTop: 0
                                }, 200);

                            } else {

                                $("html, body").animate({
                                    scrollTop: $(window).scrollTop() - 2
                                }, 200);

                            }
                        }, 250);
                        //reset initialPage if from filter
                        $("#initial_page").trigger("change");
                    }
                }

                if (fromScrollTop || fromScrollBottom) {
                    $('#is_loading').val('0');

                    /**
                     * Ajout d'une min-height sur les blocs image rayon. Permet d'éviter l'effet de
                     * clignotement de la page (du au lazyload) si on scrolle depuis le bas.
                     */

                    if ($('body.category').length) {
                        var origin = $('.item_container:not(.push)').find('img.watched'),
                            target = $('span.ill_img'),
                            targetHeight = origin.height();

                        target.each(function () {
                        })
                    }
                }

                if (fromScrollBottom && !fromScrollTop) {
                    $("#page_start").val(startPage + 1);

                    if ($("#page_start").val() >= 1) {
                        $("#initial_page").val(page);
                    }
                }

                setTimeout(function () {

                    var loader = $('.button + .loader');

                    if (loader.hasClass('loading')) {

                        loader.removeClass('loading');
                    } else {

                        loader.parent().removeClass('loading');
                    }

                    if ($(btn).closest('.drawer').length && $(btn).closest('.drawer').attr('id')) {

                        var menuId = $(btn).closest('.drawer').attr('id').replace('_menu', '');

                        toggleMenu(menuId);
                    }
                }, 200);

                /**
                 * UPDATE BOTTOM GAUGE WIDTH NB PRODUCTS LOADED / NB PRODUCTS TOTAL + BTN INITIAL STATE (No loader)
                 */
                var total_element = parseInt(document.getElementById('totalElems').value);
                var productLoaded = $('.item_container').length;
                var percentLoaded = (productLoaded * 100 / total_element);

                $('.nbProdDisplayed').html(productLoaded + ' ' + Translator.translate('sur') + ' ' + total_element + ' ' + Translator.translate('items'));

                $('.txt_bandeau label').html('(' + total_element + ' ' + Translator.translate('2_product') + ' )');

                $('.loadedGauge').html('<span style="width: ' + percentLoaded + '%;"></span>');

                $('.loader_scroll').removeClass('loading');

                if (productLoaded >= total_element) {
                    $('.loader_scroll').hide();
                }
            }
        });
    }
}

// Updates products display in shop districts
// May be simplified
function generateNewBlocSearch(nb_product, page, btn, fromScrollBottom, fromScrollTop, type, idObj) {
    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filters_form'),
        output = {},
        is_from_page = false,
        key;

    form = Array.prototype.slice.call(form.elements, 0);

    form.forEach(function(elm) {

        if ((elm.type === 'checkbox' || elm.type === 'radio')) {

            if (elm.checked && elm.value !== '') {
                if (output[elm.name] === undefined) {

                    output[elm.name] = [];
                }

                output[elm.name].push(elm.value);
            }
        } else if (elm.type === 'hidden') {
            if (elm.value !== '') {
                output[elm.name] = elm.value;
            }
        }
    });

    if (fromScrollTop || fromScrollBottom) {
        $('#is_loading').val('1');
    }

    for (key in output) {

        if (output.hasOwnProperty(key)) {

            if (output[key].constructor === Array) {

                output[key] = output[key].join('~');
            }
        }
    }

    if (nb_product !== undefined) {
        this.nb_prod.value = nb_product;
    } else {
        nb_product = this.nb_prod.value;
    }

    if (page !== undefined && page !== 'all') {
        this.page.value = output.page = page * nb_product;
        is_from_page = true;
    }

    var url = document.location.href;
    var pattern = new RegExp('\/t([0-9]*)(\/m(0|1))*\.html');

    if (pattern.test(url)) {
        var res = url.match(pattern);
        output.tri = res[1][0];
    }
    // Dtalayer V3 filter_interaction event
    if (typeof dataLayer_v3 !== 'undefined' && dataLayer_v3 !== '' && dataLayer_v3 !== null) {
        DataLayerTrigger.filerInteractionSearchMobile(output);
    }
    output.parentPath = path_relative_root;
    output.nbProducts = nb_product;
    output.is_page = is_from_page;

    // If the price exists
    if ($("input[name=price_min]").length > 0) {
        // Retrieve the price
        output.prix =
            $("#amount1").val() +
            "~" +
            $("#amount2").val();
    }

    $.ajax({
        url: path_relative_root + 'ajax_reload_search.php',
        type: 'get',
        data: output,
        success: function(res) {

            var new_elm = document.createElement('div'),
                old_elm = document.querySelector('.list_item'),
                content, itm_length;

            if (btn && btn.nextElementSibling && (btn.nextElementSibling.classList.contains('btn_tunnel') || btn.nextElementSibling.classList.contains('loader'))) {

                btn.nextElementSibling.style.display = 'none';
            }

            res = res.split('><><');

            content = res[0].trim();
            itm_length = parseFloat(res[1]);

            if (content !== '') {

                if (!fromScrollBottom && !fromScrollTop) {
                    new_elm.className = 'list_item';
                    new_elm.innerHTML = content;
                    old_elm = document.querySelector('#scroll_items');
                    $(old_elm).find('list_item').remove();
                }

                if (!isNaN(page)) {
                    old_elm.setAttribute('data-p', (page * nb_product));
                }

                if (fromScrollBottom || fromScrollTop) {
                    $(".loader_scroll").css("display", "none");
                } else {
                    backToTop();
                    old_elm.innerHTML = "";
                }

                document.getElementById('nbr_items').innerHTML = itm_length;
                document.getElementById('totalElems').value = itm_length;

                paginate(page + 1);

                if (!fromScrollBottom && !fromScrollTop) {
                    old_elm.appendChild(new_elm);
                }

                if (fromScrollTop) {
                    var firstMsg = $('#scroll_items .item_container:first');
                    var curOffset = firstMsg.offset().top - $(document).scrollTop();
                    old_elm.insertAdjacentHTML('afterbegin', content);
                    $(document).scrollTop(firstMsg.offset().top - curOffset);
                    // The reverse for the bottom
                } else if (fromScrollBottom) {
                    old_elm.insertAdjacentHTML('beforeend', content);
                }

                /** LEGACY **/

                var js_compteur = $('.vf_countdown').length;
                var js_encart = content.match(/\/\/vf_begin([\s]+[\w$(){}\[\]=;\0,\'\".:\/<&!+% ]*)+\/\/vf_end/g);

                if (js_compteur != null) {
                    try {
                        var cnt_compteur = js_compteur.length;

                        for (var i = 0; i < cnt_compteur; i++) {
                            eval(js_compteur[i].replace(/(vf_compteur_begin|vf_compteur_end)/g, ""));
                        }
                    } catch (e) {}
                }
                
                if (js_encart != null) {
                    try {
                        js_encart = js_encart[0].replace(/(\/\/vf_begin|\/\/vf_end)/g, "");
                        eval(js_encart);
                    } catch (e) {}
                }

                /** END LEGACY **/

                if (parseFloat($('#filters_menu').css('right')) === 0) {

                    toggleMenu('filters', 'right');
                }

                if (window.lazyload) {

                    lazyload.init();
                }

                if (typeof lazyloadImage === 'function') {
                    lazyloadImage();
                }

                if (typeof generateNewBlocSearchExtra === 'function') {
                    generateNewBlocSearchExtra();
                }

                /* Add total product number of filtered items */
                $("#filters_form_submit").html(Translator.translate("apply_item_filters") + " (" + parseFloat(itm_length) + ")");
            }
        },
        complete: function() {
            $('#totalElems').trigger('change');
            $('#bottom_reached').val("0").trigger('change');
            $('#page').trigger('change');

            setTimeout(function() {

                var loader = $('.button + .loader');

                if (loader.hasClass('loading')) {

                    loader.removeClass('loading');
                } else {

                    loader.parent().removeClass('loading');
                }
                if ($(btn).closest('.drawer').length && $(btn).closest('.drawer').attr('id')) {
                    var menuId = $(btn).closest('.drawer').attr('id').replace('_menu', '');
                    toggleMenu(menuId);
                }
            }, 200);

            if (fromScrollTop || fromScrollBottom) {
                $('#is_loading').val('0');

                /**
                 * Adding a min-height to the rayon image blocks.
                 * This prevents the flickering effect on the page (due to lazy loading) when scrolling from the bottom.
                 */

                if ($('body.category').length) {
                    var origin = $('.item_container:not(.push)').find('img.watched'),
                        target = $('span.ill_img'),
                        targetHeight = origin.height();

                    target.each(function() {
                        $(this).css({ minHeight: targetHeight });
                    })
                }
            }

            if (!fromScrollBottom && !fromScrollTop) {
                $("#initial_page").val(1);
            } else if (fromScrollBottom && !fromScrollTop) {
                $("#initial_page").val(page + 1);
            }

        }
    });
}

/* barre de recherche header mobile */
function searchToggle() {

    var top_search = $('#top_search'),
        main_menu_btn = $('#main_menu_btn'),
        search_home = $('#search_home'),
        shade = $('#shade');
    header = $('#site_head .banner_wrapper');

    shade.removeClass('forSearch');

    if (top_search.hasClass('opened')) {
        if (main_menu_btn.hasClass('menu_open')) {

            main_menu_btn.click();
            search_home.focus();
        } else {

            top_search.removeClass('opened');
            header.removeClass('opened');
            shade.removeClass('visible');
        }
    } else {


        if (main_menu_btn.hasClass('menu_open')) {

            main_menu_btn.click();
            top_search.addClass('opened');
            header.addClass('opened');
            search_home.focus();
            shade.addClass('visible forSearch');

            shade.on('click', function () {

                if (!search_home.val()) {

                    searchToggle();
                }
            });
        } else {
            top_search.addClass('opened');
            header.addClass('opened');
            search_home.focus();
            shade.addClass('visible forSearch');

            shade.on('click', function () {

                if (!search_home.val()) {

                    searchToggle();
                }
            });
        }

        $('body').addClass('noscroll')
    }
}

function clearSearch() {

    var search_home = $('#search_home');

    if (!search_home.val()) {

        searchToggle();
    } else {

        search_home.val('');
        search_home.focus();
    }

    $('body').removeClass('noscroll')
}

function openMultiShad(id) {
    $("#" + id).fadeIn(200, function () {
        $("#" + id).slideDown(600);
    });

    $("#shade").css("display", "block").fadeTo("slow", 0.8).on("click", function () {
        closeMultiShad(id);
    });
}

// Move tunnel ariane below title for basket page, without override view or js function
function moveTunnelElements() {
    if (($('.tunnel_step_title.cart_title').length && $('.tunnel_step_title.cart_title > .main_ariane').length == 0)) {
        $('.main_ariane').insertAfter($('.tunnel_step_title.cart_title'));
        $('.tunnel_step_title').css('opacity', '1');
    }
    if ($('body.step_1 .pushBasketWrapperText').length) {
        $('.pushBasketWrapperText').prependTo($('body.step_1 .sub_tunnel.basket'));
    }
}

$(window).on("basketToWish_success", function (data) {
    moveTunnelElements();
})

$(window).on("wishToBasket_success", function (data) {
    moveTunnelElements();
})

$(window).on("changeQte_success", function (data) {
    moveTunnelElements();
})

/*add "*" in placeholder input info form if his not present*/
var clientForm = document.querySelectorAll(".customer #clientForm")
if (clientForm.length > 0) {
    clientForm = clientForm[0].querySelectorAll("input[placeholder]")
    for (elem of clientForm) {
        var strSize = elem.placeholder.length
        if (elem.placeholder[strSize - 1] !== "*") {
            elem.placeholder = elem.placeholder + "*";
        }
    }
}

function toggleAccountMenu() {
    var $btn = $(this),
        menu = $(document.getElementById('account_menu')),
        marker = $('.marker', menu),
        body = $('body'),
        shad = document.getElementById('shade');
    offset = this.offsetLeft + (0.5 * this.offsetWidth);
    offset += -6;
    marker.css('left', offset);
    if (menu.hasClass('active')) {
        body.removeClass('noscroll');
        $btn.add(menu).removeClass('active');
        shad.classList.remove('visible');
        shad.classList.remove('account-menu');
    } else {
        body.addClass('noscroll');
        $btn.add(menu).addClass('active');
        shad.classList.add('visible');
        shad.classList.add('account-menu');
    }
}

document.addEventListener('DOMContentLoaded', function () {
    setTimeout(function () {
        const toggleMenu = (trigger, target) => {
            trigger.on('click touch tap', function (event) {
                event.preventDefault();
                let currentTarget = $(this).next(target);
                let isActive = currentTarget.hasClass('open');

                trigger.not(this).removeClass('active');
                $(".menu_part.bottom").find(target).not(currentTarget).slideUp().removeClass('open');

                $(this).toggleClass('active', !isActive);
                currentTarget.slideToggle(!isActive).toggleClass('open', !isActive);
            });
        };

        if ($('#left_menu .main_menu_itm').length) {
            toggleMenu($('.menu_part.bottom .main_menu_itm .block_lnk'), '.submenu_wrapper');
            if ($('#left_menu .menu_subcategory.has_objects').length) {
                toggleMenu($('.menu_subcategory.has_objects .ss_cat_name'), '.objet_cat');
            }
        }
    }, 800);
});

/* FAQ */

/* Handle the display of search results and themes based on user input */
function faqinit(array_questions) {
    $(document).on('change keyup focus', '#faq_search', function () {
        $('#theme_page .theme').each(function () {
            $(this).removeClass('active');
        });

        showTabFaq();

        var search = $(this).val();

        if (search && search.length >= 3) {

            $('.search_results li').show().removeClass("hasMatch");
            $('#theme_global').removeClass('actif');
            var array_match = [];

            $.each(array_questions, function (i, val) {

                val["nom"] = val["nom"].toUpperCase();
                var match = val["nom"].match(search.toUpperCase());

                if (match) {
                    array_match.push(match);
                    $('.search_results').show();
                    $('li#brand_' + val['id']).addClass("hasMatch");
                }

            });

            $('.brand_item:not(.hasMatch)').hide();

            if (array_match.length == 0) {
                $('.search_results').hide();
                $('#theme_global').addClass('actif');
            }
        } else {
            $('.search_results').hide();
            $('.search_results li').show().removeClass("hasMatch");
            $('#theme_global').addClass('actif');
        }
    });

    var click_in_process = false;

    $('.search_results').mousedown(function () {
        click_in_process = true;
    });

    $('.search_results').mouseup(function () {
        click_in_process = false;
        $('#faq_search').focus();
    });

    $('#theme_global .theme').on('click', function () {
        var id = $(this).data('id');
        var theme = $('#theme_page');

        var isActive = $(this).hasClass('active');

        $(this).toggleClass('active').siblings().removeClass('active');
        $('#theme_page .theme').hide();

        $('#theme_page .theme[data-id="' + id + '"]').show();

        $('.title_detail_sub').css('display', 'none');
        theme.addClass('actif');
        theme.insertAfter($(this));
        theme.show();

        if (!isActive) {
            $('.flexAccount').css('display', 'none');
        } else {
            $('.flexAccount').css('display', 'block');
        }

        showTabFaq(id, 1);
    });
}

document.addEventListener('DOMContentLoaded', function () {
    // make a listener on the body, if the click is on #shade and #filters_menu is .open, close it
    document.body.addEventListener('click', function (e) {
        if (e.target.id === 'shade' && $('#filters_menu').hasClass('open')) {
            toggleMenu('filters', 'right');
        }
    });
});

// Check request validity of the order return
function checkValidityRequestOfReturn() {
    var selectId = $(this).attr('id');
    var selectedValue = $(this).val();

    if (selectId.includes("return_quantity")) {
        valueIdTailleQuantity = selectId.split("return_quantity_")[1];

        if (selectedValue != 0) {
            $('#' + selectId).addClass('choice_locked');
            if (!$('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both').prop("disabled", false);
                $('#' + selectId).addClass('choice_unlocked_both');
            }
        } else {
            if ($('#' + selectId).hasClass('choice_locked') && !$('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both');
                $('#' + selectId).addClass('choice_unlocked_both');
                if (document.querySelector('.choice_locked_both')) {
                    $('#return_choice_' + valueIdTailleQuantity).addClass('choice_locked_both');
                    $('#' + selectId).addClass('choice_locked_both');
                    $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_unlocked_both');
                    $('#' + selectId).removeClass('choice_unlocked_both');
                }
            } else if ($('#' + selectId).hasClass('choice_locked') && $('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both').prop("disabled", true);
                $('#return_choice_' + valueIdTailleQuantity).val(0);
                $('#' + selectId).addClass('choice_unlocked_both');
                $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_locked_both')
                $('#' + selectId).removeClass('choice_locked_both');
                if (document.querySelector('.choice_locked_both')) {
                    $('#return_choice_' + valueIdTailleQuantity).addClass('choice_locked_both');
                    $('#' + selectId).addClass('choice_locked_both');
                    $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_unlocked_both');
                    $('#' + selectId).removeClass('choice_unlocked_both');
                }
            }
        }
    } else if (selectId.includes("return_choice")) {
        valueIdTailleChoice = selectId.split("return_choice_")[1];
        if (selectedValue != 0) {
            $('#' + selectId).addClass('choice_locked');
            $('#return_quantity_' + valueIdTailleChoice).addClass('choice_locked_both');
            $('#' + selectId).addClass('choice_locked_both');
            $('#return_quantity_' + valueIdTailleChoice).removeClass('choice_unlocked_both');
            $('#' + selectId).removeClass('choice_unlocked_both');
        } else {
            if ($('#' + selectId).hasClass('choice_locked') && $('#return_quantity_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_quantity_' + valueIdTailleChoice).addClass('choice_unlocked_both');
                $('#' + selectId).addClass('choice_unlocked_both');
                $('#return_quantity_' + valueIdTailleChoice).removeClass('choice_locked_both');
                $('#' + selectId).removeClass('choice_locked_both');
            }
        }
    }

    if (!$('.choice_unlocked_both').length && $('.choice_locked').length) {
        $(".divBtnPrintPdf").show();
    } else {
        $(".divBtnPrintPdf").hide();
    }
}

$(document).ready(function () {
    $(".return_products_form select").change(function () {
        var selectedElement = $(this);
        checkValidityRequestOfReturn.call(selectedElement);
    });
});